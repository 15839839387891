<template>
  <div class="dataPack">
    <div class="room-box">
      <div class="paperList">
        <div v-for="(item, index) in list" :key="index" class="item">
          <!-- <img src="@/assets/img/question/item.png" /> -->
          <img class="item-image" src="@/assets/img/study/pager.png">
          <div class="item-content">
            <div class="title-title ellipsis">{{ item.name }}</div>
            <div class="item-tips">共{{ item.subjectNum }}题</div>
          </div>
          <div v-if="
              item.isWork == 'false' && IsPurchase && detail.roomStatus == 2
            " class="keep-learn" @click="goLearn(item, false)">
            马上做题
          </div>
          <div v-if="IsPurchase && item.isWork != 'false'" class="keep-learn keep-check" @click="goLearn(item, true)">
            查看成绩
          </div>
        </div>
      </div>
      <div class="room-detail">
        <div class="detail">
          <div class="detailName">{{ detail.name }}</div>
        </div>
        <div class="time">
          <div>开始时间：{{ detail.startTime }}</div>
          <div class="m-t-12">结束时间：{{ detail.endTime }}</div>
        </div>
        <div class="pageNum">共{{ list.length }}套试卷</div>
      </div>
    </div>

    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { AnswerClient } from '@/api/task/answerClient'
import { getInfo, getToken } from '@/api/cookies'
const answerClient = new AnswerClient()
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { getClass } from '@/api/home'

export default {
  components: {},
  data () {
    return {
      classId: '',
      roomId: '',
      detail: {}, // 试卷包详情
      list: {},
      IsPurchase: false
    }
  },
  created () {
    this.roomId = this.$route.query.roomId
      ? String(this.$route.query.roomId)
      : null
    this.classId = this.$route.query.classId
      ? String(this.$route.query.classId)
      : null
    this.userInfo = getInfo()
    this.getDetail()
    if (this.$route.query.IsPurchase) {
      this.IsPurchase = this.$route.query.IsPurchase
    } else {
      getClass(this.classId).then((res) => {
        this.IsPurchase = res.data.IsPurchase
      })
    }
  },
  methods: {
    // 获取试卷包列表
    getDetail () {
      answerClient
        .getPcRoomDetails(
          this.roomId,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.detail = res.data.VirtualRoom
          this.detail.SubjectNum = res.data.SubjectNum
          this.list = res.data.PaperList
        })
    }, 
    goLearn (item, isSubmited) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      const sub = {
        title: item.name,
        roomId: this.roomId,
        paperId: item.id,
        examType: 3,
        isSubmited: isSubmited,
        classId: this.classId,
        IsPurchase: this.IsPurchase
      }
      const data = Vue.prototype.export(sub)
      this.$router.push(`/quesTion/examDetails?${data}`)
    },
    // 立即购买
    goOrder (type, item) {
      const token = getToken()
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem('userId'),
            item.name,
            type,
            item.name,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dataPack {
  background-color: #f5f6fa;
}
.room-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 0px 50px;

  .room-detail {
    flex-shrink: 0;
    width: 288px;
    height: 532px;
    background: #ffffff;

    margin-left: 32px;
    .detail {
      width: 288px;
      height: 160px;
      background: url("../../../assets/img/question/icon.png");
      background-size: 100% 100%;

      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 32px;
      color: #ffffff;

      padding: 34px 30px;

      .detailName {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
    .time {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      line-height: 22px;
      color: #333333;

      margin-top: 20px;
      padding: 0px 16px;
      width: 100%;
    }
    .pageNum {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      line-height: 22px;
      color: #ff5105;
      padding: 0px 16px;

      margin-top: 20px;
    }
  }
}
/* 里办 */
/* 试卷 试卷包 */
.paperList {
  width: 880px;
  height: 660px;
  background: #ffffff;
  padding: 0px 30px;
  scrollbar-width: none;
  overflow-y: scroll;
  .item {
    position: relative;
    padding: 32px 0px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    .item-image {
      width: 60px;
      height: 80px;
      flex-shrink: 0;
      background: #f7f8f9;
      margin-right: 12px;
    }
    .item-content {
      height: 80px;
      width: calc(100% - 172px);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-right: 12px;
      .item-title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
      .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 658px;
      }
    }
    .keep-learn {
      flex-shrink: 0;
    }
  }
  /* .item {
    width: 100%;
    display: flex;
    align-items: center;
    height: 132px;
    padding: 0px 30px 0px 20px;
    .item-img {
      flex-shrink: 0;
      width: 92px;
      height: 92px;
      background: linear-gradient(135deg, #fc7f6e 0%, #ffbd00 100%);
    }
    .item-title {
      width: 100%;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      height: 92px;
      justify-content: space-between;
      .title-text {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        line-height: 22px;
        color: #333333;
      }
      .item-tips {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 22px;
        color: #666666;
      }
    }
  } */
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
